import React from "react";
import FlipMove from "react-flip-move";
import classnames from "classnames";
import ContentLoader from "react-content-loader";
import { UserID, INote } from "../types";
import styles from "../styles.module.css";
import { useFolderNotes, useCurrentFolder } from "../hooks";

export function NotesSidebarShimmer({
  showNotes,
  showFolders,
}: {
  showNotes: boolean;
  showFolders: boolean;
}) {
  return (
    <nav
      style={showNotes ? { display: "block" } : {}}
      className={classnames(
        "flex-initial",
        "overflow-y-auto",
        "hidden",
        styles["sidebar"],
        {
          [styles["xs-block"]]: !showFolders,
        }
      )}
    >
      <ContentLoader
        viewBox="0 0 200 245"
        // backgroundColor="#ededed"
        // foregroundColor="#dcdcdb"
      >
        {[0, 1, 2].map((n) => (
          <rect
            key={n}
            x="0"
            y={n * 49 + 1}
            rx="4"
            ry="4"
            width="200"
            height={49 - 2}
          />
        ))}
      </ContentLoader>
    </nav>
  );
}

export default function NotesSidebar({
  showFolders,
  showNotes,
  children,
}: {
  showFolders: boolean;
  showNotes: boolean;
  notebookOwnerID: UserID;
  loggedInUserID: UserID;
  children: (note: INote) => React.ReactNode;
}) {
  const folder = useCurrentFolder();
  const currentFolderID = folder?.ref["@ref"].id || null;
  const notes = useFolderNotes(currentFolderID);

  return (
    <nav
      style={showNotes ? { display: "block" } : {}}
      className={classnames(
        "flex-initial",
        "overflow-y-auto",
        "hidden",
        styles["sidebar"],
        {
          [styles["xs-block"]]: !showFolders,
        }
      )}
    >
      <ul>
        {notes && currentFolderID && (
          <FlipMove
            appearAnimation={false}
            enterAnimation={true}
            leaveAnimation={false}
            /* `key` forces a remount when navigating between folders, which prevents the enterEnimation */
            key={currentFolderID}
          >
            {notes.map((note: INote) => children(note))}
          </FlipMove>
        )}
      </ul>
    </nav>
  );
}
