import { INote, IFolder } from "./types";

interface FetchJSONParams<T> {
  url: string;
  method: "GET" | "POST";
  data: T;
  identity: any;
}

async function fetchJSON<T>({
  url,
  method,
  data,
  identity,
}: FetchJSONParams<T>) {
  const { isLoggedIn, getFreshJWT } = identity;

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (isLoggedIn) {
    headers["Authorization"] = `Bearer ${await getFreshJWT()}`;
  }

  const fetchParams: RequestInit = {
    method,
    headers,
  };
  if (data != null) {
    fetchParams.body = JSON.stringify(data);
  }
  const resp = await fetch(url, fetchParams);

  if (!resp.ok) {
    throw new Error(await resp.text());
  }

  return resp.json();
}

interface APIParams<T> {
  data: T;
  identity: any;
}

export function getAllFolders({
  data: { userID },
  identity,
}: APIParams<{ userID: string }>): Promise<Array<IFolder>> {
  return fetchJSON({
    method: "GET",
    url: `/.netlify/functions/folders-read-all?user_id=${userID}`,
    identity,
    data: null,
  });
}

export function getAllNotes({
  data: { folderID },
  identity,
}: APIParams<{ folderID: string }>): Promise<Array<INote>> {
  return fetchJSON({
    method: "GET",
    url: `/.netlify/functions/notes-read-all?folder_id=${folderID}`,
    identity,
    data: null,
  });
}

export function updateNote({
  data: { noteID, folderID, noteContent, pinned, publiclyEditable },
  identity,
}: APIParams<{
  noteID: string;
  folderID: string;
  noteContent: string;
  pinned: boolean;
  publiclyEditable: boolean;
}>): Promise<INote> {
  return fetchJSON({
    method: "POST",
    url: `/.netlify/functions/notes-update`,
    data: {
      note_id: noteID,
      folder_id: folderID,
      text: noteContent,
      pinned: pinned,
      publicly_editable: publiclyEditable,
    },
    identity,
  });
}

export function updateFolder({
  data: { folderID, name, publiclyEditable },
  identity,
}: APIParams<{
  folderID: string;
  name: string;
  publiclyEditable: boolean;
}>): Promise<IFolder> {
  return fetchJSON({
    method: "POST",
    url: `/.netlify/functions/folders-update`,
    data: {
      folder_id: folderID,
      name: name,
      publicly_editable: publiclyEditable,
    },
    identity,
  });
}

export function createNote({
  data: { folderID, userID },
  identity,
}: APIParams<{ folderID: string; userID: string }>): Promise<INote> {
  return fetchJSON({
    method: "POST",
    url: `/.netlify/functions/notes-create`,
    data: {
      user_id: userID,
      folder_id: folderID,
      text: "",
    },
    identity,
  });
}

export function createFolder({
  data: { userID },
  identity,
}: APIParams<{ userID: string }>): Promise<IFolder> {
  return fetchJSON({
    method: "POST",
    url: `/.netlify/functions/folders-create`,
    data: {
      user_id: userID,
      name: "New Folder",
    },
    identity,
  });
}

export function deleteFolder({
  data: { folderID },
  identity,
}: APIParams<{ folderID: string }>) {
  return fetchJSON({
    method: "POST",
    url: `/.netlify/functions/folders-delete`,
    data: {
      folder_id: folderID,
    },
    identity,
  });
}

export function deleteNote({
  data: { noteID },
  identity,
}: APIParams<{ noteID: string }>) {
  return fetchJSON({
    method: "POST",
    url: `/.netlify/functions/notes-delete`,
    data: {
      note_id: noteID,
    },
    identity,
  });
}
