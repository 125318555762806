import React from "react";
import { useIdentityContext } from "react-netlify-identity-widget";
import classnames from "classnames";
import { useDrag } from "react-dnd";
import { INote, UserID } from "../types";
import styles from "../styles.module.css";
import { canModifyDataForUserID, permalink } from "../helpers";
import { useMutateNote, useCurrentNote, useCurrentFolder } from "../hooks";
import { useHistory } from "react-router";

export default function NoteLabel({
  note,
  setShowNotes,
  notebookOwnerID,
  loggedInUserID,
}: {
  note: INote;
  showNotes: boolean;
  setShowNotes: CallableFunction;
  notebookOwnerID: UserID;
  loggedInUserID: UserID;
}) {
  const identity = useIdentityContext();
  const currentFolder = useCurrentFolder();
  const currentFolderID = currentFolder?.ref["@ref"].id;
  const currentNote = useCurrentNote();
  const noteID = note.ref["@ref"].id;

  const item = { noteID, currentFolderID, type: "note" };

  const [mutateNote] = useMutateNote();

  const [{ opacity }, drag] = useDrag({
    item,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.3 : 1,
    }),
    end(item, monitor) {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && dropResult.folderID) {
        if (dropResult.folderID !== currentFolderID) {
          mutateNote({
            data: {
              noteID,
              folderID: dropResult.folderID,
              noteContent: note.data.text,
              pinned: note.data.pinned,
              publiclyEditable: note.data.publicly_editable,
            },
            identity,
          });
        }
      }
    },
  });

  const history = useHistory();

  return (
    <li
      key={note.ref["@ref"].id}
      className={classnames("relative", styles["note-li"], {
        [styles["pinned-note"]]: note.data.pinned,
      })}
      ref={drag}
      style={{ opacity }}
    >
      <button
        onClick={() => {
          history.push(
            permalink({
              userID: notebookOwnerID,
              currentFolderID: currentFolderID,
              currentNoteID: noteID,
            })
          );
          setShowNotes(false);
        }}
        className={classnames(
          "w-full",
          "py-2",
          "px-4",
          "text-left",
          "px-3",
          styles["sidebar-post"],
          {
            [styles["sidebar-selected"]]:
              noteID === currentNote?.ref["@ref"].id,
          }
        )}
      >
        <div className="font-semibold truncate">
          {note.data.text.split("\n")[0] || "New Note"}
        </div>
        <div>{new Date(note.ts / 1000).toLocaleString()}</div>
        <button
          className={classnames("absolute", styles["pin-note-button"])}
          onClick={() => {
            if (
              currentFolderID &&
              canModifyDataForUserID(
                notebookOwnerID,
                loggedInUserID,
                note.data.publicly_editable
              )
            ) {
              mutateNote({
                data: {
                  noteID,
                  folderID: currentFolderID,
                  noteContent: note.data.text,
                  pinned: !note.data.pinned,
                  publiclyEditable: note.data.publicly_editable,
                },
                identity,
              });
            }
          }}
        >
          {canModifyDataForUserID(
            notebookOwnerID,
            loggedInUserID,
            note.data.publicly_editable
          ) ? (
            <span role="img" aria-label="Pin note">
              📌
            </span>
          ) : null}
        </button>
      </button>
    </li>
  );
}
