import { useEffect } from "react";
import { useIdentityContext } from "react-netlify-identity-widget";
import { UserID, INote, FolderID, IFolder } from "../types";
import { useQuery, useMutation, queryCache } from "react-query";
import {
  getAllNotes,
  createNote,
  deleteNote,
  getAllFolders,
  createFolder,
  deleteFolder,
  updateFolder,
} from "../api";
import { debouncedUpdateNote, permalink } from "../helpers";
import {
  useCreateFaunaCollectionItem,
  findIndexFaunaItem,
  removeFaunaItem,
  modifyFaunaItem,
  useDeleteFaunaCollectionItem,
} from "./fauna";
import { getItem, setItem } from "../localStorage";
import { useHistory, useParams } from "react-router";

export function useLoggedInUserID() {
  const identity = useIdentityContext();
  const loggedInUserID = identity.isLoggedIn ? (identity as any).user.id : null;
  return loggedInUserID;
}

export function useNotebookOwnerID(): UserID {
  let { viewingUserID } = useParams();

  if (!viewingUserID) {
    viewingUserID = "fake-user-id";
  }

  return viewingUserID;
}

export function useCurrentNotebookFolders() {
  const viewingUserID = useNotebookOwnerID();
  const folders = useUserFolders(viewingUserID as UserID);
  return folders;
}

export function useUserFolders(userID: UserID): Array<IFolder> {
  const identity = useIdentityContext();

  const initialData = getItem(`folders.${userID}`);

  const { data: folders, refetch } = useQuery(
    ["folders", userID],
    () => getAllFolders({ data: { userID }, identity }),
    {
      onSuccess: (resp) => {
        setItem(`folders.${userID}`, resp);
      },
    }
  );

  // prepopulate from localstorage, but force a refresh
  if (userID) {
    const queryData = queryCache.getQueryData(["folders", userID]);
    if (queryData == null && initialData != null) {
      queryCache.setQueryData(["folders", userID], initialData);
      refetch({ force: true });
    }
  }

  return folders ? sortedFolders(folders) : [];
}

export function useCreateNote() {
  const viewingUserID = useNotebookOwnerID();
  const { currentFolderID } = useParams();
  return useCreateFaunaCollectionItem({
    createItem: createNote,
    getQueryKey: (note: INote) => ["notes", note.data.folder_id],
    getNewItemUrl: (id: string) =>
      permalink({
        userID: viewingUserID,
        currentFolderID: currentFolderID,
        currentNoteID: id,
      }),
  });
}

export function useCreateFolder() {
  const viewingUserID = useNotebookOwnerID();
  return useCreateFaunaCollectionItem({
    createItem: createFolder,
    getQueryKey: (folder: IFolder) => ["folders", folder.data.user_id],
    getNewItemUrl: (id: string) =>
      permalink({
        userID: viewingUserID,
        currentFolderID: id,
      }),
  });
}

export function useDeleteNote() {
  const currentFolder = useCurrentFolder();
  const currentFolderID = currentFolder?.ref["@ref"].id;
  return useDeleteFaunaCollectionItem({
    deleteItem: deleteNote,
    queryKey: ["notes", currentFolderID],
    getItemID: (params) => params.data.noteID,
  });
}

export function useDeleteFolder() {
  const viewingUserID = useNotebookOwnerID();

  return useDeleteFaunaCollectionItem({
    deleteItem: deleteFolder,
    queryKey: ["folders", viewingUserID],
    getItemID: (params) => params.data.folderID,
  });
}

function sortedNotes(notes: Array<INote>): Array<INote> {
  const newNotes = [...notes];
  newNotes.sort((a, b) => b.ts - a.ts);
  const pinnedNotes = newNotes.filter((note) => note.data["pinned"]);
  const unpinnedNotes = newNotes.filter((note) => !note.data["pinned"]);
  return [...pinnedNotes, ...unpinnedNotes];
}

function sortedFolders(folders: Array<IFolder>): Array<IFolder> {
  const newFolders = [...folders];
  newFolders.sort((a, b) => b.ts - a.ts);
  return newFolders;
}

export function useFolderNotes(
  folderID: FolderID | null | undefined
): Array<INote> | null {
  const identity = useIdentityContext();
  const initialData = getItem(`notes.${folderID}`);
  const { data: notes, refetch } = useQuery(
    // when currentFolderID is falsy, this query won't execute at all
    folderID ? ["notes", folderID] : null,
    () => getAllNotes({ data: { folderID: folderID as FolderID }, identity }),
    {
      onSuccess: (resp) => {
        setItem(`notes.${folderID}`, resp);
      },
    }
  );

  // prepopulate from localstorage, but force a refresh
  if (folderID) {
    const queryData = queryCache.getQueryData(["notes", folderID]);
    if (queryData == null && initialData != null) {
      queryCache.setQueryData(["notes", folderID], initialData);
      refetch({ force: true });
    }
  }

  return notes ? sortedNotes(notes) : null;
}

export function useCurrentFolder(): IFolder | null {
  const viewingUserID = useNotebookOwnerID();
  const history = useHistory();
  let { currentFolderID } = useParams();

  const folders = useCurrentNotebookFolders();

  useEffect(() => {
    if (
      (!currentFolderID || findIndexFaunaItem(folders, currentFolderID) < 0) &&
      folders &&
      folders.length > 0
    ) {
      const firstFolderID = folders[0].ref["@ref"].id;
      history.replace(
        permalink({
          userID: viewingUserID,
          currentFolderID: firstFolderID,
        })
      );
    }
  }, [folders, viewingUserID, currentFolderID, history]);

  if (folders && currentFolderID) {
    return (
      folders.find((item) => item.ref["@ref"].id === currentFolderID) || null
    );
  }
  return null;
}

export function useCurrentNote(): INote | null {
  const viewingUserID = useNotebookOwnerID();
  const { currentNoteID, currentFolderID } = useParams();
  const history = useHistory();

  const notes = useFolderNotes(currentFolderID);

  useEffect(() => {
    if (
      (!currentNoteID || findIndexFaunaItem(notes, currentNoteID) < 0) &&
      notes &&
      notes.length > 0
    ) {
      const firstNoteID = notes[0].ref["@ref"].id;
      history.replace(
        permalink({
          userID: viewingUserID,
          currentFolderID: currentFolderID,
          currentNoteID: firstNoteID,
        })
      );
    }
  }, [notes, currentFolderID, viewingUserID, currentNoteID, history]);

  if (notes && currentNoteID) {
    return notes.find((item) => item.ref["@ref"].id === currentNoteID) || null;
  }
  return null;
}

export function useMutateFolder() {
  return useMutation(updateFolder, {
    onSuccess: (folder: IFolder) => {
      queryCache.refetchQueries(["folders", folder.data.user_id]);
    },
  });
}

export function useMutateNote() {
  const viewingUserID = useNotebookOwnerID();
  const { currentFolderID, currentNoteID } = useParams();
  const history = useHistory();

  return useMutation(debouncedUpdateNote, {
    onMutate: (params) => {
      // Snapshot the previous value
      const previousNotes = queryCache.getQueryData([
        "notes",
        currentFolderID,
      ]) as Array<INote>;

      const previousTargetFolderNotes = queryCache.getQueryData([
        "notes",
        params.data.folderID,
      ]) as Array<INote>;

      const temporaryTimestamp = Date.now() * 1000;

      if (params.data.folderID === currentFolderID) {
        // modify the note if it's still in this folder
        const newNotes = modifyFaunaItem(
          previousNotes,
          params.data.noteID,
          (item: INote) => {
            return {
              ...item,
              ts: temporaryTimestamp,
              data: {
                ...item.data,
                text: params.data.noteContent,
                pinned: params.data.pinned,
                publicly_editable: params.data.publiclyEditable,
              },
            };
          }
        );
        queryCache.setQueryData(["notes", currentFolderID], newNotes);
      } else {
        // remove it otherwise.
        queryCache.setQueryData(
          ["notes", currentFolderID],
          removeFaunaItem(previousNotes, params.data.noteID)
        );
        // ...and update the cache for the target folder
        queryCache.setQueryData(
          ["notes", params.data.folderID],
          sortedNotes([
            ...previousTargetFolderNotes,
            {
              ref: {
                "@ref": {
                  id: params.data.noteID,
                },
              },
              ts: temporaryTimestamp,
              data: {
                user_id: viewingUserID,
                folder_id: params.data.folderID,
                text: params.data.noteContent,
                pinned: params.data.pinned,
                publicly_editable: params.data.publiclyEditable,
              },
            },
          ])
        );
        // if this is the current note, navigate to thew new folder
        if (currentNoteID === params.data.noteID) {
          history.push(
            permalink({
              userID: viewingUserID,
              currentFolderID: params.data.folderID,
              currentNoteID: currentNoteID,
            })
          );
        }
      }

      // Return a rollback function, in case the mutation fails
      return () => {
        queryCache.setQueryData(["notes", currentFolderID], previousNotes);
        queryCache.setQueryData(
          ["notes", params.data.folderID],
          previousTargetFolderNotes
        );
      };
    },
    onError: (_err, _data, rollback: any) => rollback(),
  });
}
