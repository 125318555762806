import lscache from "lscache";

// bump this number to invalidate localStorage caches
const CACHE_VERSION = 2;
const PREFIX = `notes_exposed_state_v${CACHE_VERSION}`;

function prefixKey(key: string): string {
  return `${PREFIX}.${key}`;
}

export function getItem(key: string): any {
  return lscache.get(prefixKey(key));
}

export function setItem(key: string, value: any) {
  const THIRTY_DAYS = 60 /* minutes */ * 24 /* hours */ * 30; /* days */
  lscache.set(prefixKey(key), value, THIRTY_DAYS);
}
