import React from "react";
import FlipMove from "react-flip-move";
import classnames from "classnames";
import styles from "../styles.module.css";
import { IFolder, UserID } from "../types";

export default function FoldersList({
  notebookOwnerID,
  folders,
  children,
}: {
  notebookOwnerID: UserID;
  folders: Array<IFolder>;
  children: (folder: IFolder) => React.ReactNode;
}) {
  return (
    <ul className="flex-1 w-full overflow-y-auto">
      {folders && (
        <FlipMove
          appearAnimation={false}
          enterAnimation={true}
          leaveAnimation={false}
          /* `key` forces a remount changing users, which prevents the enterEnimation */
          key={notebookOwnerID}
        >
          {/* for when we have an All Notes folder
             <button
              className={classnames("w-full px-3 py-1 text-left outline-none", {
                [styles["directory-selected"]]: currentFolderID === null,
              })}
              onClick={(event) => {
                dispatch({
                  type: actions.SELECT_FOLDER,
                  payload: { folderID: null },
                });
              }}
            >
              All Notes
            </button> */}

          {folders.map((folder: any) => (
            <li
              key={folder.ref["@ref"].id}
              className={classnames(
                "flex folder-label",
                styles["folder-label"]
              )}
            >
              {children(folder)}
            </li>
          ))}
        </FlipMove>
      )}
    </ul>
  );
}
