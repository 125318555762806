import React from "react";
import { useIdentityContext } from "react-netlify-identity-widget";
import classnames from "classnames";
import { List } from "react-content-loader";
import { UserID } from "../types";
import styles from "../styles.module.css";
import { canModifyDataForUserID } from "../helpers";
import {
  useCurrentNote,
  useMutateNote,
  useCreateNote,
  useCurrentFolder,
} from "../hooks";

export function NoteShimmer() {
  return (
    <div
      className={classnames(
        "flex-1 px-4 py-2 overflow-y-auto outline-none resize-none",
        styles["page-colour"]
      )}
    >
      <List style={{ width: "500px" }} />
    </div>
  );
}

export default function Note({
  setShowFolders,
  setShowNotes,
  notebookOwnerID,
  loggedInUserID,
}: {
  setShowFolders: CallableFunction;
  setShowNotes: CallableFunction;
  notebookOwnerID: UserID;
  loggedInUserID: UserID;
}) {
  const identity = useIdentityContext();
  const currentFolder = useCurrentFolder();
  const currentFolderID = currentFolder?.ref["@ref"].id;
  const note = useCurrentNote();
  const [mutateNote] = useMutateNote();
  const [createNote] = useCreateNote();

  const noteID = note ? note.ref["@ref"].id : null;

  return (
    <textarea
      readOnly={
        note
          ? !canModifyDataForUserID(
              notebookOwnerID,
              loggedInUserID,
              note.data.publicly_editable
            )
          : true
      }
      className={classnames(
        "flex-1 px-4 py-2 overflow-y-auto outline-none resize-none",
        styles["page-colour"]
      )}
      value={(note && note.data.text) || ""}
      onClick={() => {
        setShowFolders(false);
        setShowNotes(false);
        if (!note && currentFolderID) {
          createNote({
            data: { folderID: currentFolderID, userID: notebookOwnerID },
            identity,
          });
        }
      }}
      onInput={(event) => {
        if (note && noteID && currentFolderID) {
          // fire and forget
          mutateNote({
            data: {
              noteID: noteID,
              folderID: currentFolderID,
              noteContent: (event.target as HTMLTextAreaElement).value,
              pinned: note.data.pinned,
              publiclyEditable: note.data.publicly_editable,
            },
            identity,
          });
        }
      }}
    />
  );
}
