import React from "react";
import classnames from "classnames";
import { INote } from "../types";
import styles from "../styles.module.css";
import FoldersSidebar, { FoldersSidebarShimmer } from "./FoldersSidebar";
import NoteLabel from "./NotesLabel";
import NotesSidebar, { NotesSidebarShimmer } from "./NotesSidebar";
import Note, { NoteShimmer } from "./Note";
import { useNotebookOwnerID, useLoggedInUserID } from "../hooks";

export default function Notes({
  showFolders,
  setShowFolders,
  showNotes,
  setShowNotes,
}: {
  showFolders: boolean;
  setShowFolders: CallableFunction;
  showNotes: boolean;
  setShowNotes: CallableFunction;
}) {
  const notebookOwnerID = useNotebookOwnerID();
  const loggedInUserID = useLoggedInUserID();
  return (
    <div className={classnames("flex h-full", styles["window-minus-taskbar"])}>
      <React.Suspense
        fallback={<FoldersSidebarShimmer showFolders={showFolders} />}
      >
        <FoldersSidebar
          showFolders={showFolders}
          setShowFolders={setShowFolders}
          setShowNotes={setShowNotes}
          notebookOwnerID={notebookOwnerID}
          loggedInUserID={loggedInUserID}
        />
      </React.Suspense>

      <React.Suspense
        fallback={
          <NotesSidebarShimmer
            showFolders={showFolders}
            showNotes={showNotes}
          />
        }
      >
        <NotesSidebar
          showFolders={showFolders}
          showNotes={showNotes}
          loggedInUserID={loggedInUserID}
          notebookOwnerID={notebookOwnerID}
        >
          {(note: INote) => (
            <NoteLabel
              note={note}
              showNotes={showNotes}
              setShowNotes={setShowNotes}
              notebookOwnerID={notebookOwnerID}
              loggedInUserID={loggedInUserID}
            />
          )}
        </NotesSidebar>
      </React.Suspense>

      <React.Suspense fallback={<NoteShimmer />}>
        <Note
          setShowFolders={setShowFolders}
          setShowNotes={setShowNotes}
          notebookOwnerID={notebookOwnerID}
          loggedInUserID={loggedInUserID}
        />
      </React.Suspense>
    </div>
  );
}
