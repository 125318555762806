import React, { useState } from "react";
import { useIdentityContext } from "react-netlify-identity-widget";
import classnames from "classnames";
import ContentLoader from "react-content-loader";
import { IFolder, UserID } from "../types";
import styles from "../styles.module.css";
import FoldersList from "./FoldersList";
import FolderLabel from "./FolderLabel";
import { useCurrentNotebookFolders, useCreateFolder } from "../hooks";

export function FoldersSidebarShimmer({
  showFolders,
}: {
  showFolders: boolean;
}) {
  return (
    <nav
      style={showFolders ? { display: "flex" } : {}}
      className={classnames(
        "flex-initial",
        "w-48",
        "overflow-y-auto",
        "flex-grow-0",
        "hidden",
        "md:flex",
        "flex-col",
        "items-start",
        "h-full",
        "pb-1",
        styles["directories"],
        styles["sidebar"]
      )}
    >
      <ContentLoader
        viewBox="0 0 169 72"
        backgroundColor="#ededed"
        foregroundColor="#dcdcdb"
      >
        {[0, 1].map((n) => (
          <rect
            key={n}
            x="0"
            y={n * 24 + 1}
            rx="4"
            ry="4"
            width="169"
            height={24 - 2}
          />
        ))}
      </ContentLoader>
    </nav>
  );
}

export default function FoldersSidebar({
  showFolders,
  setShowFolders,
  setShowNotes,
  notebookOwnerID,
  loggedInUserID,
}: {
  showFolders: boolean;
  setShowFolders: CallableFunction;
  setShowNotes: CallableFunction;
  notebookOwnerID: UserID;
  loggedInUserID: UserID;
}) {
  const [createdNewFolder, setCreatedNewFolder] = useState(false);
  const identity = useIdentityContext();
  const folders = useCurrentNotebookFolders();
  const [createFolder] = useCreateFolder();

  return (
    <nav
      style={showFolders ? { display: "flex" } : {}}
      className={classnames(
        "flex-initial",
        "w-48",
        "overflow-y-auto",
        "flex-grow-0",
        "hidden",
        "md:flex",
        "flex-col",
        "items-start",
        "h-full",
        "pb-1",
        styles["directories"],
        styles["sidebar"]
      )}
    >
      <FoldersList notebookOwnerID={notebookOwnerID} folders={folders}>
        {(folder: IFolder) => (
          <FolderLabel
            folder={folder}
            overrideEditMode={createdNewFolder}
            setCreatedNewFolder={setCreatedNewFolder}
            showFolders={showFolders}
            setShowFolders={setShowFolders}
            setShowNotes={setShowNotes}
            notebookOwnerID={notebookOwnerID}
            loggedInUserID={loggedInUserID}
          />
        )}
      </FoldersList>
      {notebookOwnerID === "fake-user-id" ||
      notebookOwnerID === loggedInUserID ? (
        <button
          className="flex-none px-3 mt-2 h-8 text-gray-700 hover:text-gray-900"
          onClick={() => {
            createFolder({ data: { userID: notebookOwnerID }, identity });
          }}
        >
          <span className="align-middle ml-1">
            <span role="img" aria-label="New folder">
              ➕
            </span>
            New Folder
          </span>
        </button>
      ) : null}
    </nav>
  );
}
