import React from "react";
import classnames from "classnames";
import styles from "../styles.module.css";

export default function BackButtons({
  showFolders,
  setShowFolders,
  showNotes,
  setShowNotes,
}: any) {
  const clickToShowNotesSidebar = (
    <button
      className={classnames(
        "inline-block bg-gray-100 rounded px-2 mx-1",
        styles["xs-hidden"]
      )}
      onClick={() => {
        setShowNotes(true);
        setShowFolders(false);
      }}
    >
      <span role="img" aria-label="Show notes">
        🔙
      </span>
    </button>
  );
  const clickToShowFolders = (
    <button
      className={classnames(
        "bg-gray-100 rounded px-2 mx-1",
        styles["xs-block"],
        styles["md-hidden"]
      )}
      onClick={() => {
        setShowFolders(true);
        if (showNotes) {
          setShowNotes(false);
        }
      }}
    >
      <span role="img" aria-label="Show folders">
        🔙
      </span>
    </button>
  );
  if (showFolders) {
    return <div></div>;
  } else if (!showNotes && !showFolders) {
    return clickToShowNotesSidebar;
  } else if (showNotes && !showFolders) {
    return clickToShowFolders;
  } else return <div></div>;
}
