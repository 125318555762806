import { IFaunaItem } from "../types";
import { useMutation, queryCache, AnyQueryKey } from "react-query";
import { useHistory } from "react-router";

export function findIndexFaunaItem<T>(
  arr: Array<IFaunaItem<T>> | null,
  id: string | null | undefined
): number {
  if (arr == null || id == null) {
    return -1;
  }
  return arr.findIndex((item: IFaunaItem<T>) => item.ref["@ref"].id === id);
}

export function removeFaunaItem<T>(
  arr: Array<IFaunaItem<T>> | null,
  id: string
): Array<IFaunaItem<T>> | null {
  if (arr == null) {
    return null;
  }
  return arr.filter((item: IFaunaItem<T>) => item.ref["@ref"].id !== id);
}

export function modifyFaunaItem<T>(
  arr: Array<IFaunaItem<T>> | null,
  id: string,
  modify: (item: IFaunaItem<T>) => IFaunaItem<T>
): Array<IFaunaItem<T>> | null {
  if (arr == null) {
    return null;
  }
  return arr.map((item) => {
    if (item.ref["@ref"].id === id) {
      return modify(item);
    } else {
      return item;
    }
  });
}

export function useCreateFaunaCollectionItem<P, T>({
  createItem,
  getQueryKey,
  getNewItemUrl,
}: {
  createItem: (params: P) => Promise<IFaunaItem<T>>;
  getQueryKey: (item: IFaunaItem<T>) => string | AnyQueryKey;
  getNewItemUrl: (id: string) => string;
}) {
  const history = useHistory();
  return useMutation(createItem, {
    onSuccess: (item: IFaunaItem<T>) => {
      queryCache.setQueryData(getQueryKey(item), (previousItems: Array<T>) => [
        item,
        ...previousItems,
      ]);
      history.push(getNewItemUrl(item.ref["@ref"].id));
    },
  });
}

export function useDeleteFaunaCollectionItem<P, T, K>({
  deleteItem,
  queryKey,
  getItemID,
}: {
  deleteItem: (params: P) => Promise<T>;
  queryKey: string | AnyQueryKey;
  getItemID: (params: P) => string;
}) {
  return useMutation(deleteItem, {
    onMutate: (params) => {
      const previousItems = queryCache.getQueryData(queryKey) as Array<
        IFaunaItem<K>
      >;
      queryCache.setQueryData(
        queryKey,
        removeFaunaItem(previousItems, getItemID(params))
      );
      return () => queryCache.setQueryData(queryKey, previousItems);
    },
    onError: (_err, _params, rollback: any) => rollback(),
  });
}
