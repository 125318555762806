import React, { useState } from "react";
import {
  IdentityModal,
  useIdentityContext,
} from "react-netlify-identity-widget";
import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "../styles.module.css";
import { canModifyDataForUserID, permalink } from "../helpers";
import {
  useNotebookOwnerID,
  useLoggedInUserID,
  useCurrentNote,
  useCreateNote,
  useDeleteNote,
} from "../hooks";
import BackButtons from "./BackButtons";

function DeleteNoteButton() {
  const viewingUserID = useNotebookOwnerID();
  const loggedInUserID = useLoggedInUserID();
  const [deleteNote] = useDeleteNote();
  const identity = useIdentityContext();
  const note = useCurrentNote();

  if (
    note &&
    canModifyDataForUserID(
      viewingUserID,
      loggedInUserID,
      note.data.publicly_editable
    )
  ) {
    return (
      <button
        className="bg-gray-100 rounded px-2 mx-1"
        onClick={() => {
          deleteNote({
            data: { noteID: note.ref["@ref"].id },
            identity,
          });
        }}
      >
        <span role="img" aria-label="Delete note">
          🗑<span className="hidden sm:inline-block">Delete Note</span>
        </span>
      </button>
    );
  } else {
    return null;
  }
}

export default function TaskBar({
  showFolders,
  setShowFolders,
  showNotes,
  setShowNotes,
}: any) {
  const viewingUserID = useNotebookOwnerID();
  const loggedInUserID = useLoggedInUserID();
  const identity = useIdentityContext();
  const { currentFolderID } = useParams();
  const [dialog, setDialog] = useState(false);
  const history = useHistory();
  const [createNote] = useCreateNote();

  return (
    <nav className={classnames(styles["taskbar"], "flex px-3 py-2")}>
      <nav className="hidden sm:block">
        <button
          className={classnames(
            styles["traffic-light"],
            styles["traffic-light--red"],
            "ml-0"
          )}
        />
        <button
          className={classnames(
            styles["traffic-light"],
            styles["traffic-light--yellow"],
            "ml-2"
          )}
        />
        <button
          className={classnames(
            styles["traffic-light"],
            styles["traffic-light--green"],
            "ml-2"
          )}
        />
      </nav>

      <IdentityModal
        showDialog={dialog}
        onCloseDialog={() => setDialog(false)}
        onLogin={(user) => {
          if (user) {
            setDialog(false);
            history.push(
              permalink({
                userID: user.id,
              })
            );
          }
        }}
        onSignup={(user) => {
          if (user) {
            setDialog(false);
            history.push(
              permalink({
                userID: user.id,
              })
            );
          }
        }}
      />
      <nav className="block sm:hidden">
        {identity.isLoggedIn && identity.user ? (
          <Link to="/">{identity.user.email}</Link>
        ) : (
          <button onClick={() => setDialog(true)}>Sign in</button>
        )}
      </nav>

      <BackButtons
        showFolder={showFolders}
        setShowFolders={setShowFolders}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
      />

      {viewingUserID === "fake-user-id" || viewingUserID === loggedInUserID ? (
        <div>
          <React.Suspense fallback={null}>
            <DeleteNoteButton />
          </React.Suspense>
          <button
            className="bg-gray-100 rounded px-2 mx-1"
            onClick={() => {
              if (currentFolderID) {
                createNote({
                  data: {
                    folderID: currentFolderID,
                    userID: viewingUserID,
                  },
                  identity,
                });
              }
            }}
          >
            <span role="img" aria-label="New note">
              ➕<span className="hidden sm:inline-block">New Note</span>
            </span>
          </button>
        </div>
      ) : null}
    </nav>
  );
}
