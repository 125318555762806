import React from "react";
import { Redirect } from "react-router-dom";
import { useLoggedInUserID } from "../hooks";

export default function LandingPage() {
  const loggedInUserID = useLoggedInUserID();
  if (loggedInUserID === null) {
    return <Redirect to="/demo" />;
  } else {
    return <Redirect to={`/u/${loggedInUserID}`} />;
  }
}
