import { UserID, FolderID, NoteID } from "./types";
import debounce from "debounce-promise";
import { updateNote } from "./api";

export const tma = "1eb485a0-bf11-4a7c-a6fd-ee85fe47eb38";
export const DEMO_USER_ID = "fake-user-id";

export const canModifyDataForUserID = (
  viewingUserID: UserID,
  loggedInUserID: UserID | null,
  publiclyEditable: boolean
) => {
  if (viewingUserID === DEMO_USER_ID) {
    return publiclyEditable || loggedInUserID === tma;
    // if not the demo
  } else if (viewingUserID) {
    return loggedInUserID === viewingUserID;
  }
};

export const debouncedUpdateNote = debounce(updateNote, 400);

export function permalink({
  userID,
  currentFolderID,
  currentNoteID,
}: {
  userID: UserID;
  currentFolderID?: FolderID;
  currentNoteID?: NoteID;
}) {
  let url;
  if (userID === "fake-user-id") {
    url = `/demo`;
  } else {
    url = `/u/${userID}`;
  }
  if (currentFolderID) {
    url += `/${currentFolderID}`;
  }
  if (currentNoteID) {
    url += `/${currentNoteID}`;
  }
  return url;
}
