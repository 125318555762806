import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import styles from "../styles.module.css";
import { IFolder, UserID } from "../types";
import { canModifyDataForUserID, permalink } from "../helpers";
import { useIdentityContext } from "react-netlify-identity";
import { useDrop } from "react-dnd";
import { useMutateFolder, useDeleteFolder, useCurrentFolder } from "../hooks";
import { useHistory } from "react-router";

export default function FolderLabel({
  folder,
  overrideEditMode,
  setCreatedNewFolder,
  showFolders,
  setShowFolders,
  setShowNotes,
  notebookOwnerID,
  loggedInUserID,
}: {
  folder: IFolder;
  overrideEditMode: boolean;
  setCreatedNewFolder: CallableFunction;
  showFolders: boolean;
  setShowFolders: CallableFunction;
  setShowNotes: CallableFunction;
  notebookOwnerID: UserID;
  loggedInUserID: UserID;
}) {
  const [editMode, setEditMode] = useState(false);
  // the text in the input field regardless of whether the user is done renaming
  const [folderName, setFolderName] = useState(folder.data.name);
  const input = useRef(null);
  const identity = useIdentityContext();
  const folderID = folder.ref["@ref"].id;
  const currentFolder = useCurrentFolder();
  const currentFolderID = currentFolder?.ref["@ref"].id;
  const [mutateFolder] = useMutateFolder();
  const [deleteFolder] = useDeleteFolder();
  const history = useHistory();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "note",
    drop: () => ({
      folderID,
    }),
    canDrop: (item) => {
      // @ts-ignore
      return item.currentFolderID !== folderID;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isDragActive = canDrop && isOver;

  useEffect(() => {
    if (
      (editMode ||
        (overrideEditMode && folder.ref["@ref"].id === currentFolderID)) &&
      canModifyDataForUserID(
        notebookOwnerID,
        loggedInUserID,
        folder.data.publicly_editable
      )
    ) {
      (input as any).current.focus();
    }
  }, [
    editMode,
    folder,
    currentFolderID,
    overrideEditMode,
    loggedInUserID,
    notebookOwnerID,
  ]);

  const updateFolderName = () => {
    setEditMode(false);
    setCreatedNewFolder(false);
    if (folderName !== folder.data.name && folderName.length > 0) {
      mutateFolder({
        data: {
          folderID,
          name: folderName,
          publiclyEditable: folder.data.publicly_editable,
        },
        identity,
      });
    } else {
      setFolderName(folder.data.name);
    }
  };

  if (
    (editMode ||
      (overrideEditMode && folder.ref["@ref"].id === currentFolderID)) &&
    canModifyDataForUserID(
      notebookOwnerID,
      loggedInUserID,
      folder.data.publicly_editable
    )
  ) {
    return (
      <div className={classnames(styles["directory-rename"])}>
        <input
          className="mx-2 px-1 text-left my-1 outline-none rounded"
          type="text"
          ref={input}
          value={folderName}
          onChange={(event) => {
            setFolderName(event.target.value);
          }}
          onBlur={updateFolderName}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              updateFolderName();
            }
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        <button
          className={classnames("w-full px-3 py-1 text-left outline-none", {
            [styles["directory-selected"]]:
              folder.ref["@ref"].id === currentFolderID,
            [styles["directory-focus"]]: isDragActive,
          })}
          onClick={(event) => {
            // if we're already on the folder, clicking again triggers rename
            // disable rename on mobile view
            if (folder.ref["@ref"].id === currentFolderID && !showFolders) {
              setEditMode(true);
            }

            // switch folders
            else {
              history.push(
                permalink({
                  userID: notebookOwnerID,
                  currentFolderID: folderID,
                })
              );
              setShowFolders(false);
              setShowNotes(true);
            }
          }}
          ref={drop}
        >
          {folderName}
        </button>
        {canModifyDataForUserID(
          notebookOwnerID,
          loggedInUserID,
          folder.data.publicly_editable
        ) ? (
          <button
            className={classnames("relative right-0", styles["delete-folder"])}
            onClick={() => {
              deleteFolder({
                data: {
                  folderID,
                },
                identity,
              });
            }}
          >
            <span role="img" aria-label="Delete folder">
              🗑
            </span>
          </button>
        ) : null}
      </>
    );
  }
}
