import React, { useEffect, useState } from "react";
import {
  IdentityModal,
  useIdentityContext,
} from "react-netlify-identity-widget";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "../styles.module.css";
import { permalink } from "../helpers";

export default function TopNavBar() {
  const identity = useIdentityContext();
  const [dialog, setDialog] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAdmin = urlParams.get("admin") === "1";
    if (isAdmin) {
      window.location.href = atob(
        "aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g/dj1vSGc1U0pZUkhBMA=="
      );
    }
  }, []);
  return (
    <>
      <IdentityModal
        showDialog={dialog}
        onCloseDialog={() => setDialog(false)}
        onLogin={(user) => {
          if (user) {
            setDialog(false);
            history.push(
              permalink({
                userID: user.id,
              })
            );
          }
        }}
        onSignup={(user) => {
          if (user) {
            setDialog(false);
            history.push(
              permalink({
                userID: user.id,
              })
            );
          }
        }}
      />
      <nav className="w-screen absolute top-0 hidden sm:block">
        <ul className={classnames(styles["topnav"], "shadow-lg text-sm")}>
          <li className="inline-block font-bold px-3">
            <Link to="/">Notes</Link>
          </li>
          {identity.isLoggedIn && identity.user ? (
            <>
              <li className="inline-block px-3">
                <Link to="/">{identity.user.email}</Link>
              </li>
              <li className="inline-block px-3">
                <button
                  onClick={() => {
                    identity.logoutUser().then(() => {
                      history.push("/demo");
                    });
                  }}
                >
                  Logout
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="inline-block px-3">
                <button onClick={() => setDialog(true)}>Sign in</button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
}
