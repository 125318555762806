import React, { useState } from "react";
import classnames from "classnames";
import styles from "../styles.module.css";
import Notes from "./Notes";
import TaskBar from "./TaskBar";

export default function NotesWindow() {
  //only triggered for responsive screens
  const [showFolders, setShowFolders] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  return (
    <div
      className={classnames(
        "container",
        "mx-auto",
        "w-9/12",
        "shadow-xl",
        "overflow-hidden",
        "min-h-full",
        "min-w-full",
        "sm:min-h-0",
        "sm:min-w-0",
        styles["window"],
        styles["page-colour"]
      )}
    >
      <TaskBar
        showFolders={showFolders}
        setShowFolders={setShowFolders}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
      />
      <Notes
        showFolders={showFolders}
        setShowFolders={setShowFolders}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
      />
    </div>
  );
}
