import React from "react";
import { IdentityContextProvider } from "react-netlify-identity-widget";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import "../styles.css";
import "../meyer-reset.css";
// used by react-netlify-identity-widget
import "../react-netlify-identity-widget.css";
import "@reach/tabs/styles.css";
import FullScreen from "./FullScreen";
import TopNavBar from "./TopNavBar";
import LandingPage from "./LandingPage";
import { ReactQueryConfigProvider } from "react-query";
import NotesWindow from "./NotesWindow";

const queryConfig = {
  suspense: true,
};

export default function App() {
  const url = process.env.REACT_APP_NETLIFY_IDENTITY_URL as string;

  return (
    <>
      <Router>
        <IdentityContextProvider url={url}>
          <ReactQueryConfigProvider config={queryConfig}>
            <DndProvider backend={Backend}>
              <FullScreen>
                <TopNavBar />
                <Switch>
                  <Route exact path="/">
                    <LandingPage />
                  </Route>
                  <Route path="/demo/:currentFolderID?/:currentNoteID?">
                    <NotesWindow />
                  </Route>
                  <Route path="/u/:viewingUserID/:currentFolderID?/:currentNoteID?">
                    <NotesWindow />
                  </Route>
                </Switch>
              </FullScreen>
            </DndProvider>
          </ReactQueryConfigProvider>
        </IdentityContextProvider>
      </Router>
    </>
  );
}
